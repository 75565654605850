<!-- <script setup>
import InputText from "primevue/inputtext"

const model = defineModel()
</script>

<template>
	<InputText v-model="model"></InputText>
</template>

<style scoped lang="scss">
.p-inputtext {
	border-radius: 4px;
	border: 1px solid #d3d6e9;
	background: #fff;
	padding:0.75rem 1.125rem;
	&::placeholder {
		color: #9D9DB0;
	}
}
</style> -->

<template>
	<div class="custom-input-text">
		<span class="p-float-label">
			<InputText :id="pLabel" v-model="model" :style="style" :disabled="disabled" @update:modelValue="updateValue"/>
			<label  :style="labelStyle" :for="pLabel">{{ pLabel }}</label>
		</span>
	</div>
</template>

<script setup>
import { computed } from "vue"
import InputText from "primevue/inputtext"

const props = defineProps({
	modelValue: { type: [String, Number], default: "" },
	pLabel: { type: [String, Number], default: "" },
	disabled: { type: Boolean, default: false },
	style: { type:Object, default: {} },
	labelStyle: { type:Object, default: {} },
})

const emit = defineEmits(["update:modelValue"])

const model = computed({
	get() {
		return props.modelValue
	},
	set(value) {
		return emit("update:modelValue", value)
	},
})

const updateValue = () => {}
</script>

<style scoped lang="scss">
.custom-float-label {
	top: 1.2em;
	left: 1.2em;
	font-size: 0.8em;
}
.custom-input-text {
	.p-float-label {
		.p-inputtext {
			background-color: #F5FBFF;
			// font-size: 1.4rem;
			font-size: 1.125rem;
			border: none;
			border-radius: 5;
			height: 58px;
			padding: 20px 16px 3px 16px;
			width: 100%;
			border-bottom: 1px solid #ECECEC;
			text-overflow: ellipsis;
			&:hover {
				border-bottom: 1px solid #C6C6C6;
			}
			&:focus {
				outline: none;
				border-bottom: 1px solid #0F62E6;
			}
			&:enabled:focus {
				// box-shadow: 0 0 0 0.02rem white;
			}
			&:focus + label {
				@extend .custom-float-label;
			}
		}

		label {
			font-size: 1rem;
			//font-size: .725rem;
			font-weight: 400;
		}

		.p-filled + label {
			@extend .custom-float-label;
		}
	}
	&.error .p-float-label .p-inputtext {
		border-bottom: 2px solid #FF6600;
	}
}
</style>
