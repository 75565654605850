import _ from 'lodash';
import { customJoi as Joi } from '@/models/customJoi';




export const PersonnelSchema = {
  personnelType: Joi.array().min(1).required(),
  personnelFullName: Joi.string().required(),
  personnelIcNum: Joi.string().required(),
  personnelEmail: Joi.string().lowercase().trim().email({ tlds: { allow: false } }).required(),
  personnelMobile: Joi.number().required(),
  personnelDesignation: Joi.string().required(),

  typeOfServices:Joi.array().min(0),
  personnelNationality:Joi.when(Joi.ref('typeOfServices'), {
    is:  Joi.array().length(0), 
    then: Joi.string().allow(''),
    otherwise: Joi.when('typeOfServices', {
      is: Joi.array().items(Joi.string().invalid('crossBorder').required()), 
      then: Joi.string().allow(''),  
      otherwise: Joi.string().required()  
    })
  }),
  personnelGender: Joi.when(Joi.ref('typeOfServices'), {
    is:  Joi.array().length(0), 
    then: Joi.string().allow(''),
    otherwise: Joi.when('typeOfServices', {
      is: Joi.array().items(Joi.string().invalid('crossBorder').required()), 
      then: Joi.string().allow(''),  
      otherwise: Joi.string().required()  
    })
  }),
  personnelDob:Joi.when(Joi.ref('typeOfServices'), {
    is:  Joi.array().length(0), 
    then: Joi.string().allow('').default(''),
    otherwise: Joi.when('typeOfServices', {
      is: Joi.array().items(Joi.string().invalid('crossBorder').required()), 
      then: Joi.string().allow('').default(''),  
      otherwise: Joi.string().required()  
    })
  }),
  personnelIdIssueCountry:Joi.when(Joi.ref('typeOfServices'), {
    is:  Joi.array().length(0), 
    then: Joi.string().allow(''),
    otherwise: Joi.when('typeOfServices', {
      is: Joi.array().items(Joi.string().invalid('crossBorder').required()), 
      then: Joi.string().allow(''),  
      otherwise: Joi.string().required()  
    })
  }),
  personnelIdExpiryDate:Joi.string().allow('').default('')
  // Joi.when(Joi.ref('typeOfServices'), {
  //   is:  Joi.array().length(0), 
  //   then: Joi.string().allow('').default(''),
  //   otherwise: Joi.when('typeOfServices', {
  //     is: Joi.array().items(Joi.string().invalid('crossBorder').required()), 
  //     then: Joi.string().allow('').default(''),  
  //     otherwise: Joi.string().required()  
  //   })
  // }),
}

export const DigitalFinanceApplicationSchema = {

  //step01
  // step1:{
  //   typeOfServices:Joi.array().min(0),
  // },
  step1: {

    //:(
    typeOfServices:Joi.array().min(1).required(),
    

    companyName: Joi.string().required(),
    companyTradingName: Joi.string().allow(''),
    companyRegNo: Joi.string().required(),
    companyWebsite: Joi.string().required(),  
    companyEntityType: Joi.string().required(),
    companyEntityOtherType: Joi.string().when('companyEntityType', {
      is:'Others',
      then:Joi.string().required(),
      otherwise:Joi.string().allow('')
    }),
    addressDiffAsAcraFlag: Joi.boolean().default(false),
    //companyAddress1: Joi.string().allow(''), 
    companyAddress1: Joi.string().when( 'addressDiffAsAcraFlag', {
      is: true, then: Joi.string().required(),
      is: false, then: Joi.string().allow(''),
    }),  
    companyAddress2: Joi.string().allow(''), 
    companyPostalCode: Joi.string().when( 'addressDiffAsAcraFlag', {
      is: true, then: Joi.string().required(),
      is: false, then: Joi.string().allow(''),
    }),
    companyState:Joi.string().when('addressDiffAsAcraFlag', {
      is:true, then: Joi.string().required(),
      otherwise:Joi.string().allow('')
    }),
    companyCity:Joi.string().when('addressDiffAsAcraFlag', {
      is:true, then: Joi.string().required(),
      otherwise:Joi.string().allow('')
    }),
    companyCountry:Joi.string().when('addressDiffAsAcraFlag', {
      is:true, then: Joi.string().required(),
      otherwise:Joi.string().allow('')
    }),
    //companyPostalCode: Joi.string().allow(''), 
    companyMainIndustry: Joi.string().required(),
    companySubIndustry: Joi.string().allow(''),

    contactPersonName:Joi.when(Joi.ref('typeOfServices'), {
      is:Joi.array().items(Joi.string().valid('crossBorder','paymentLink').required()), 
      then: Joi.string().required(),
      otherwise:Joi.string().allow('')
    }),
    // contactPersonName:Joi.string().default("").allow(""),
    contactPersonMobileCountryCode: Joi.when(Joi.ref('typeOfServices'), {
      is:Joi.array().items(Joi.string().valid('crossBorder','paymentLink').required()), 
      then: Joi.number().required(),
      otherwise:Joi.number().allow('')
    }),
    contactPersonMobile: Joi.when(Joi.ref('typeOfServices'), {
      is:Joi.array().items(Joi.string().valid('crossBorder','paymentLink').required()), 
      then: Joi.string().required(),
      otherwise:Joi.string().allow('')
    }),
    contactPersonEmail:Joi.when(Joi.ref('typeOfServices'), {
      is:Joi.array().items(Joi.string().valid('crossBorder','paymentLink').required()), 
      then: Joi.string().required(),
      otherwise:Joi.string().allow('')
    }),
    contactPersonDesignation:Joi.when(Joi.ref('typeOfServices'), {
      is:Joi.array().items(Joi.string().valid('crossBorder','paymentLink').required()), 
      then: Joi.string().required(),
      otherwise:Joi.string().allow('')
    }),

    businessHours:Joi.when(Joi.ref('typeOfServices'), {
      is:  Joi.array().length(0), 
      then: Joi.string().allow(''),
      otherwise: Joi.when('typeOfServices', {
        is: Joi.array().items(Joi.string().invalid('paymentLink').required()), 
        then: Joi.string().allow(''),  
        otherwise: Joi.string().required()  
      })
    }),

  },
  step2: {
    //:(
    typeOfServices:Joi.array().min(0),

    businessDescription: Joi.string().required(),
    expectedTurnover: Joi.number().required(),
    sourceOfFund:Joi.string().required(),
    countryOperations: Joi.array().min(1).required(),
    tradingCurrencies: Joi.array().min(1).required(),
    relevantPurposes: Joi.array().min(1).required(),
    expectedTransactionAmt: Joi.number().required(),


    numberOfEmployees:Joi.when(Joi.ref('typeOfServices'), {
      is:  Joi.array().length(0), 
      then: Joi.number().default(0),
      otherwise: Joi.when('typeOfServices', {
        is: Joi.array().items(Joi.string().valid('crossBorder','paymentLink').required()), 
        then: Joi.number().min(1).message("is not allowed to be empty."),
        otherwise: Joi.number().default(0),
      })
    }),
    
    currentMonthlySale:Joi.when(Joi.ref('typeOfServices'), {
      is:  Joi.array().length(0), 
      then: Joi.number().default(0),
      otherwise: Joi.when('typeOfServices', {
        is: Joi.array().items(Joi.string().invalid('paymentLink').required()), 
        then: Joi.number().default(0),
        otherwise: Joi.number().min(1).message("is not allowed to be empty."),
      })
    }),
    estimatedAverageBillingAmount:Joi.when(Joi.ref('typeOfServices'), {
      is:  Joi.array().length(0), 
      then: Joi.number(),
      otherwise: Joi.when('typeOfServices', {
        is: Joi.array().items(Joi.string().invalid('paymentLink').required()), 
        then: Joi.number().default(0),
        otherwise: Joi.number().min(1).message("is not allowed to be empty."),
      })
    }),
    shippingFulfillmentPeriod:Joi.when(Joi.ref('typeOfServices'), {
      is:  Joi.array().length(0), 
      then: Joi.number(),
      otherwise: Joi.when('typeOfServices', {
        is: Joi.array().items(Joi.string().invalid('paymentLink').required()), 
        then: Joi.number().default(0),
        otherwise: Joi.number().min(1).message("is not allowed to be empty."),
      })
    }),

    eCommerceUsed:Joi.boolean().default(false),
    eCommercePlatformName:Joi.when(Joi.ref('eCommerceUsed'), {
      is: true, 
      then: Joi.string().required(),
      otherwise: Joi.string().default("").allow("")
    }),
    currentPaymentGateway:Joi.boolean().default(false),
    serviceProvider:Joi.when(Joi.ref('currentPaymentGateway'), {
      is: true, 
      then: Joi.string().required(),
      otherwise: Joi.string().default("").allow("")
    }),
    leaveCurrentPaymentGatewayReason:Joi.string().default("").allow(""),
    sixMonthsChargeBackRatio:Joi.number().default(0),
    acceptCreditCard:Joi.boolean().default(false),

    settlementPeriod:Joi.when(Joi.ref('typeOfServices'), {
      is:  Joi.array().length(0), 
      then: Joi.string().allow(''),
      otherwise: Joi.when('typeOfServices', {
        is: Joi.array().items(Joi.string().invalid('paymentLink').required()), 
        then: Joi.string().allow(''),  
        otherwise: Joi.string().required()  
      })
    }),
    settlementMinAmount:Joi.when(Joi.ref('typeOfServices'), {
      is:  Joi.array().length(0), 
      then: Joi.number(),
      otherwise: Joi.when('typeOfServices', {
        is: Joi.array().items(Joi.string().invalid('paymentLink').required()), 
        then: Joi.number().default(0),
        otherwise: Joi.number().min(1).message("is not allowed to be empty."),
      })
    }),
    // settlementMinAmount: 0,

    officeAreaZoned:Joi.when(Joi.ref('typeOfServices'), {
      is:  Joi.array().length(0), 
      then: Joi.string().allow(''),
      otherwise: Joi.when('typeOfServices', {
        is: Joi.array().items(Joi.string().invalid('paymentLink').required()), 
        then: Joi.string().allow(''),  
        otherwise: Joi.string().required()  
      })
    }),
    officeSpace:Joi.when(Joi.ref('typeOfServices'), {
      is:  Joi.array().length(0), 
      then: Joi.string().allow(''),
      otherwise: Joi.when('typeOfServices', {
        is: Joi.array().items(Joi.string().invalid('paymentLink').required()), 
        then: Joi.string().allow(''),  
        otherwise: Joi.string().required()  
      })
    }),

    bankName:Joi.when(Joi.ref('typeOfServices'), {
      is:  Joi.array().length(0), 
      then: Joi.string().allow(''),
      otherwise: Joi.when('typeOfServices', {
        is: Joi.array().items(Joi.string().invalid('paymentLink').required()), 
        then: Joi.string().allow(''),  
        otherwise: Joi.string().required()  
      })
    }),
    bankAccountName:Joi.when(Joi.ref('typeOfServices'), {
      is:  Joi.array().length(0), 
      then: Joi.string().allow(''),
      otherwise: Joi.when('typeOfServices', {
        is: Joi.array().items(Joi.string().invalid('paymentLink').required()), 
        then: Joi.string().allow(''),  
        otherwise: Joi.string().required()  
      })
    }),
    branchLocation:Joi.when(Joi.ref('typeOfServices'), {
      is:  Joi.array().length(0), 
      then: Joi.string().allow(''),
      otherwise: Joi.when('typeOfServices', {
        is: Joi.array().items(Joi.string().invalid('paymentLink').required()), 
        then: Joi.string().allow(''),  
        otherwise: Joi.string().required()  
      })
    }),
    beneficiaryName:Joi.when(Joi.ref('typeOfServices'), {
      is:  Joi.array().length(0), 
      then: Joi.string().allow(''),
      otherwise: Joi.when('typeOfServices', {
        is: Joi.array().items(Joi.string().invalid('paymentLink').required()), 
        then: Joi.string().allow(''),  
        otherwise: Joi.string().required()  
      })
    }),
    swiftCode:Joi.when(Joi.ref('typeOfServices'), {
      is:  Joi.array().length(0), 
      then: Joi.string().allow(''),
      otherwise: Joi.when('typeOfServices', {
        is: Joi.array().items(Joi.string().invalid('paymentLink').required()), 
        then: Joi.string().allow(''),  
        otherwise: Joi.string().required()  
      })
    })
  },
  step3: {
    //:(
    // typeOfServices:Joi.array().min(0),
    personnels: Joi.array().min(1).required(),
  },
  step4: {
    //:(
    typeOfServices:Joi.array().min(0),


    filesAcra: Joi.array().min(1).required(),
    filesMemorandum: Joi.array().min(1).required(),
    filesId: Joi.array().min(0),
    filesCoProfile: Joi.array().min(0),

    filesBankStatement:Joi.when(Joi.ref('typeOfServices'), {
      is:  Joi.array().length(0), 
      then: Joi.array().min(0),
      otherwise: Joi.when('typeOfServices', {
        is: Joi.array().items(Joi.string().invalid('paymentLink').required()), 
        then: Joi.array().min(0),
        otherwise: Joi.array().min(1).required(),
      })
    }),
    filesCheckoutFlow:Joi.when(Joi.ref('typeOfServices'), {
      is:  Joi.array().length(0), 
      then: Joi.array().min(0),
      otherwise: Joi.when('typeOfServices', {
        is: Joi.array().items(Joi.string().invalid('paymentLink').required()), 
        then: Joi.array().min(0),
        otherwise: Joi.array().min(1).required(),
      })
    }),
    filesOfficePicture:Joi.when(Joi.ref('typeOfServices'), {
      is:  Joi.array().length(0), 
      then: Joi.array().min(0),
      otherwise: Joi.when('typeOfServices', {
        is: Joi.array().items(Joi.string().invalid('paymentLink').required()), 
        then: Joi.array().min(0),
        otherwise: Joi.array().min(1).required(),
      })
    }),
  },


};

export const CorpsecRegNewCoSchema = {

  //step01
  step1: {
    companyName1: Joi.string().required(),
    companyName2: Joi.string().allow(''),
    companyName3: Joi.string().allow(''),
  },
  step2: {
    localDirectorFlag: Joi.boolean().default(true),
  },
  step3: {
    noOfShareholder: Joi.string().required(),
    corporateSharholderFlag: Joi.boolean().default(true),
  },
  step4: {
    useOurAddressFlag: Joi.boolean().default(true),
  },
  step5: {
    useAccountingFlag: Joi.boolean().default(true),
  },
  step6: {
    useEmploymentVisaSupportFlag: Joi.boolean().default(false),
    noOfEp: Joi.number().min(0).max(100).required().default(0),
    noOfLoc: Joi.number().min(0).max(100).required().default(0), 
  },  


};

export const CorpsecTransferInCoSchema = {

  step1: {
    companyName: Joi.string().required(),
    companyRegNo: Joi.string().required(),
    contactName: Joi.string().required(),
    contactEmail: Joi.string().lowercase().trim().email({ tlds: { allow: false } }).required(),
    contactMobile: Joi.string().required(),
    hearFrom: Joi.string().required(),
  },
  step2: {
    existingCorpsecFlag: Joi.boolean().default(false),
    currentCorpsecName: Joi.string().when( 'existingCorpsecFlag', {
      is: true, then: Joi.string().required(),
      is: false, then: Joi.string().allow(''),
    }),  
    currentCorpsecContactNo: Joi.string().when( 'existingCorpsecFlag', {
      is: true, then: Joi.string().required(),
      is: false, then: Joi.string().allow(''),
    }),
    currentCorpsecEmail: Joi.string().when( 'existingCorpsecFlag', {
      is: true, then: Joi.string().lowercase().trim().email({ tlds: { allow: false } }).required(),
      is: false, then: Joi.string().allow(''),
    }),   
    transferReason: Joi.string().when( 'existingCorpsecFlag', {
      is: true, then: Joi.string().required(),
      is: false, then: Joi.string().allow(''),
    }),           
  },
  step3: {
    filesAcra: Joi.array().min(1).required(),
    filesMemorandum: Joi.array().min(1).required(),
    filesId: Joi.array().min(0),    
  }, 

};


export const CorpsecRegNewCoCombineSchema = {

  //step01
  step1: {
    companyName1: Joi.string().required(),
    companyName2: Joi.string().allow(''),
    companyName3: Joi.string().allow(''),
    capitalBaseCurrency: Joi.string().required(),
    capitalAmount: Joi.number().positive().greater(0).required(),
    companyActivity1: Joi.string().required(),
    companyActivity2: Joi.string().allow(''),
    regAddressDiffFlag: Joi.boolean().default(false),
    regAddress1: Joi.string().when( 'regAddressDiffFlag', {
      is: true, then: Joi.string().required(),
      is: false, then: Joi.string().allow(''),
    }),      
    //regAddress1: Joi.string().required(),
    regAddress2: Joi.string().allow(''),
    regAddressPostalCode: Joi.string().when( 'regAddressDiffFlag', {
      is: true, then: Joi.string().required(),
      is: false, then: Joi.string().allow(''),
    }), 
    //regAddressPostalCode: Joi.string().required(),     
  },
  step2: {
    members: Joi.array().min(1).required(),
    filesId: Joi.array().min(1).required(),
  },
  step3: {
    directors: Joi.array().min(1).required(),
    confirmDirectorNotStruckOffFlag: Joi.boolean().default(false).invalid(false).messages({
      en: {'any.invalid': `Please check to confirm.`}, cn: {'any.invalid': `请勾选同意`},
    }),
    filesId: Joi.array().min(1).required(),
    nomineeDirectorFlag: Joi.boolean().default(false),
    useOurCorpSecAgreeFlag: Joi.boolean().default(false).invalid(false).messages({
      en: {'any.invalid': `Please check to confirm.`}, cn: {'any.invalid': `请勾选同意`},
    }),
    otherDirectorships: Joi.string().allow(''),
  },
  step4: {
    companyConstituionAgreeFlag: Joi.boolean().default(true).invalid(false).messages({
      en: {'any.invalid': `Please check to confirm.`}, cn: {'any.invalid': `请勾选同意`},
    }),
  },
  step5: {
    fye: Joi.string().required(),
  },
  step6: {
    applicantName: Joi.string().required(),
    applicantIdNo: Joi.string().required(),
    //applicantEmail: Joi.string().required(),
    applicantEmail: Joi.string().lowercase().trim().email({ tlds: { allow: false } }).required(),
      
    applicantDeclareFlag: Joi.boolean().default(false).invalid(false).messages({
      en: {'any.invalid': `Please check to confirm.`}, cn: {'any.invalid': `请勾选申报`},
      'any.invalid': `Please check to declare.`,
    }),
  },  


};

export const MemberSchema = {
  // memberFullName: Joi.string().required(),
  // memberIdNum: Joi.string().required(),
  // memberDob: Joi.string().required(),
  // memberEmail: Joi.string().lowercase().trim().email({ tlds: { allow: false } }).required(),
  // memberMobileCountryCode: Joi.number().required(),
  // memberMobile: Joi.number().required(),
  // memberAddress1: Joi.string().required(),
  // memberAddress2: Joi.string().allow(''),
  // memberPostalCode: Joi.string().required(),
  // memberOrdinaryShareHeld: Joi.number().invalid(0),

  memberType:Joi.string().required(),

  memberFullName:Joi.when("memberType",{
    is: "individual",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('')
  }),
  memberIdNum:Joi.when("memberType",{
    is: "individual",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('')
  }),
  memberDob:Joi.when("memberType",{
    is: "individual",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('')
  }),
  memberEmail:Joi.when("memberType",{
    is: "individual",
    then: Joi.string().lowercase().trim().email({ tlds: { allow: false } }).required(),
    otherwise: Joi.string().allow('')
  }),
  memberMobileCountryCode: Joi.when("memberType",{
    is: "individual",
    then: Joi.number().required(),
    otherwise: Joi.number().allow('').default(null)
  }),
  // memberMobileAreaCode:Joi.when("memberType",{
  //   is: "individual",
  //   then: Joi.number().allow(''),
  //   otherwise: Joi.number().allow('')
  // }),
  memberMobile:Joi.when("memberType",{
    is: "individual",
    then: Joi.number().required(),
    otherwise: Joi.number().allow('')
  }),
  memberAddress1:Joi.when("memberType",{
    is: "individual",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('')
  }),
  memberAddress2:Joi.when("memberType",{
    is: "individual",
    then: Joi.string().allow(''),
    otherwise: Joi.string().allow('').default('')
  }),
  memberPostalCode:Joi.when("memberType",{
    is: "individual",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('').default('')
  }),

  //memberAltAddress1: Joi.string().required(),

  memberAltAddress1: Joi.string().when("memberType", {
    is: "individual",
    then: Joi.string().when("altAddressFlag", {
      is: true,
      then: Joi.string().required(),
      is: false,
      then: Joi.string().allow(""),
    }),
    otherwise: Joi.string().allow(""),
  }),
  memberAltAddress2: Joi.string().allow(""),
  memberAltPostalCode: Joi.string().when("memberType", {
    is: "individual",
    then: Joi.string().when("altAddressFlag", {
      is: true,
      then: Joi.string().required(),
      is: false,
      then: Joi.string().allow(""),
    }),
    otherwise: Joi.string().allow(""),
  }),

  memberNationality:Joi.when("memberType",{
    is: "individual",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('')
  }),
  
  memberOrdinaryShareHeld:Joi.number().invalid(0),

  //company
  memberCompanyName:Joi.when("memberType",{
    is: "corporate",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('')
  }),
  memberRegNo:Joi.when("memberType",{
    is: "corporate",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('')
  }),
  memberCountryOfIncorp:Joi.when("memberType",{
    is: "corporate",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('')
  }),
  memberDateOfIncorp:Joi.when("memberType",{
    is: "corporate",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('')
  }),
  memberCompanyAddress1:Joi.when("memberType",{
    is: "corporate",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('')
  }),
  memberCompanyAddress2:Joi.when("memberType",{
    is: "corporate",
    then: Joi.string().allow(''),
    otherwise: Joi.string().allow('').default('')
  }),
  memberCompanyPostcode:Joi.when("memberType",{
    is: "corporate",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('')
  }),
  memberRepFullname:Joi.when("memberType",{
    is: "corporate",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('')
  }),
}

export const DirectorSchema = {
  directorFullName: Joi.string().required(),
  directorIdNum: Joi.string().required(),
  directorNationality: Joi.string().required(),
  directorDob: Joi.string().required(),
  directorEmail: Joi.string().lowercase().trim().email({ tlds: { allow: false } }).required(),
  directorMobileCountryCode: Joi.number().required(),
  directorMobileAreaCode: Joi.number().allow(''),
  directorMobile: Joi.number().required(),
  directorAddress1: Joi.string().required(),
  directorAddress2: Joi.string().allow(''),
  directorPostalCode: Joi.string().required(),
  altAddressFlag: Joi.boolean().default(false),
  //directorAltAddress1: Joi.string().required(),
  directorAltAddress1: Joi.string().when( 'altAddressFlag', {
    is: true, then: Joi.string().required(),
    is: false, then: Joi.string().allow(''),
  }),  
  directorAltAddress2: Joi.string().allow(''),
  //directorAltPostalCode: Joi.string().required(),  
  directorAltPostalCode: Joi.string().when( 'altAddressFlag', {
    is: true, then: Joi.string().required(),
    is: false, then: Joi.string().allow(''),
  }),    
  directorOtherPosition: Joi.string().allow(''),
}

export const MemberMYSchema = {
  memberType:Joi.string().required(),

  memberFullName: Joi.when("memberType",{
    is: "individual",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('')
  }),
  memberGender: Joi.when("memberType",{
    is: "individual",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('').default('')
  }),
  memberDob: Joi.when("memberType",{
    is: "individual",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('').default('')
  }),
  memberEmail: Joi.when("memberType",{
    is: "individual",
    then: Joi.string().lowercase().trim().email({ tlds: { allow: false } }).required(),
    otherwise: Joi.string().allow('').default('')
  }),
  
  memberMobileCountryCode: Joi.when("memberType",{
    is: "individual",
    then: Joi.number().required(),
    otherwise: Joi.number().allow('').default('')
  }),
  
  memberMobile: Joi.when("memberType",{
    is: "individual",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('').default('')
  }),
  memberAddress1: Joi.when("memberType",{
    is: "individual",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('').default('')
  }),
  memberAddress2: Joi.when("memberType",{
    is: "individual",
    then: Joi.string().allow(''),
    otherwise: Joi.string().allow('').default('')
  }),
  memberPostalCode: Joi.when("memberType",{
    is: "individual",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('').default('')
  }),
  
  memberNationality: Joi.when("memberType",{
    is: "individual",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('').default('')
  }),

  memberShareholdingAnalysis: Joi.string().required(),
  memberOrdinaryShareHeld: Joi.number().invalid(0),

  //cpompany
  memberCompanyName: Joi.when("memberType",{
    is: "corporate",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('').default('')
  }),
  memberRegNo: Joi.when("memberType",{
    is: "corporate",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('').default('')
  }),
  memberIncorpPlace: Joi.when("memberType",{
    is: "corporate",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('').default('')
  }),


  //company rep
  memberRepFullName: Joi.when("memberType",{
    is: "corporate",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('').default('')
  }),
  memberRepDob: Joi.when("memberType",{
    is: "corporate",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('').default('')
  }),
  memberRepGender: Joi.when("memberType",{
    is: "corporate",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('').default('')
  }),
  memberRepNationality: Joi.when("memberType",{
    is: "corporate",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('').default('')
  }),
  memberRepEmail: Joi.when("memberType",{
    is: "corporate",
    then: Joi.string().lowercase().trim().email({ tlds: { allow: false } }).required(),
    otherwise: Joi.string().allow('').default('')
  }),
  memberRepMobileCountryCode: Joi.when("memberType",{
    is: "corporate",
    then: Joi.number().required(),
    otherwise: Joi.number().allow('').default('')
  }),
  memberRepMobile: Joi.when("memberType",{
    is: "corporate",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('').default('')
  }),
  memberRepAddress1: Joi.when("memberType",{
    is: "corporate",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('').default('')
  }),
  memberRepAddress2: Joi.when("memberType",{
    is: "corporate",
    then: Joi.string().allow(''),
    otherwise: Joi.string().allow('').default('')
  }),
  memberRepPostalCode: Joi.when("memberType",{
    is: "corporate",
    then: Joi.string().required(),
    otherwise: Joi.string().allow('').default('')
  })
}

export const CorpsecRegNewCoCombineMYSchema = {

  //step01
  step1: {
    companyName1: Joi.string().required(),
    companyName2: Joi.string().allow(''),
    companyName3: Joi.string().allow(''),
    capitalBaseCurrency: Joi.string().required(),
    capitalAmount: Joi.number().positive().greater(0).required(),
    capitalValue: Joi.number().positive().greater(0).required(),
    companyActivity1: Joi.string().required(),
    companyActivity2: Joi.string().allow(''),
    regAddressDiffFlag: Joi.boolean().default(false),
    regAddress1: Joi.string().when( 'regAddressDiffFlag', {
      is: true, then: Joi.string().required(),
      is: false, then: Joi.string().allow(''),
    }),      
    //regAddress1: Joi.string().required(),
    regAddress2: Joi.string().allow(''),
    regAddressPostalCode: Joi.string().when( 'regAddressDiffFlag', {
      is: true, then: Joi.string().required(),
      is: false, then: Joi.string().allow(''),
    }), 
    fye: Joi.string().required(),
    //regAddressPostalCode: Joi.string().required(),     
  },
  step2: {
    members: Joi.array().min(1).required(),
    filesId: Joi.array().min(1).required(),
    memberConsentFlag: Joi.boolean().default(false).invalid(false).messages({
      en: {'any.invalid': `Please check to confirm.`}, cn: {'any.invalid': `请勾选同意`},
    }),
  },
  step3: {
    directors: Joi.array().min(1).required(),
    directorConsentFlag: Joi.boolean().default(false).invalid(false).messages({
      en: {'any.invalid': `Please check to confirm.`}, cn: {'any.invalid': `请勾选同意`},
    }),
    filesId: Joi.array().min(1).required(),
    // useOurCorpSecAgreeFlag: Joi.boolean().default(false).invalid(false).messages({
    //   en: {'any.invalid': `Please check to confirm.`}, cn: {'any.invalid': `请勾选同意`},
    // }),
    // otherDirectorships: Joi.string().allow(''),

    contactPersonName: Joi.string().required(),
    contactPersonCorrespondence: Joi.string().allow(''),
    // contactPersonAddress1: Joi.string().required(),
    // contactPersonAddress2: Joi.string().allow(''),
    // contactPersonPostalCode: Joi.string().required(),
    contactPersonEmail: Joi.string().required(),
    contactPersonMobileCountryCode: Joi.number().required(),
    contactPersonMobile: Joi.string().required(),

    
  },
  step4: {
    extraService: Joi.string().allow(''),
  },
  step5: {
    personalDataPrivacyFlag: Joi.boolean().default(true).invalid(false).messages({
      en: {'any.invalid': `Please check to confirm.`}, cn: {'any.invalid': `请勾选同意`},
    }),
  },
  // step6: {
  //   applicantName: Joi.string().required(),
  //   applicantIdNo: Joi.string().required(),
  //   //applicantEmail: Joi.string().required(),
  //   applicantEmail: Joi.string().lowercase().trim().email({ tlds: { allow: false } }).required(),
      
  //   applicantDeclareFlag: Joi.boolean().default(false).invalid(false).messages({
  //     en: {'any.invalid': `Please check to confirm.`}, cn: {'any.invalid': `请勾选申报`},
  //     'any.invalid': `Please check to declare.`,
  //   }),
  // },  


};

export const DirectorMYSchema = {
  directorFullName: Joi.string().required(),
  directorNationality: Joi.string().required(),
  directorDob: Joi.string().required(),
  directorEmail: Joi.string().lowercase().trim().email({ tlds: { allow: false } }).required(),
  directorMobileCountryCode: Joi.number().required(),
  directorMobile: Joi.string().required(),
  directorGender: Joi.string().required(),
  directorResidentialAddress1: Joi.string().required(),
  directorResidentialAddress2: Joi.string().allow(''),
  directorResidentialPostalCode: Joi.string().required(),
  serviceAddressFlag: Joi.boolean().default(false),
  directorServiceAddress1: Joi.string().when( 'serviceAddressFlag', {
    is: true, then: Joi.string().required(),
    is: false, then: Joi.string().allow(''),
  }),  
  directorServiceAddress2: Joi.string().allow(''),
  directorServicePostalCode: Joi.string().when( 'serviceAddressFlag', {
    is: true, then: Joi.string().required(),
    is: false, then: Joi.string().allow(''),
  }),    
  directorDesignation: Joi.string().required(),
  directorOtherCompany: Joi.string().allow(''),
  directorOtherCompanyAppointmentDate:  Joi.string().allow(''),
}

export const CentralContactSchema = {
  subject: Joi.string().required(),
  name: Joi.string().required(),
  email: Joi.string().lowercase().trim().email({ tlds: { allow: false } }).required(),
  phoneCountryCode: Joi.number().required(),
  phone: Joi.number(),
  message: Joi.string().required(),
}


